import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ToggleButton from '@material-ui/lab/ToggleButton';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import Grow from '@material-ui/core/Grow';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    borderRadius: 0,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    color: theme.palette.primary.main,
    fontStyle: 'bold',
    fontSize: 70,
    fontWeight: 400,
    paddingRight: 100,
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      fontSize: 50,
      paddingRight: 10,
    }
  },
  style: {
    backgroundColor: 'transparent',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flex: 1
  },
  buttonStyle: {
    color: theme.palette.secondary.dark,
    fontStyle: 'bold',
    padding: theme.spacing(3),
    flexGrow: 1,
    alignSelf: 'flex-start',
    textAlign: 'center',
    display: 'block',
    "&:hover .buttonUnderline": {
      backgroundColor: 'transparent',
      textDecoration: "none",
      display: 'none'
    },
  },
  buttonUnderline: {
    backgroundColor: theme.palette.secondary.dark,
    height: 6,
    width: 40,
    margin: 'auto',

  },  
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: 0,
  },
  buttonZone: {
    display: 'flex',
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  buttonLabel: {
    fontSize: 14,
    //fontWeight: 500,
  },
  buttonLabelBold: {
    fontSize: 14,
    fontWeight: 600,
  },
  menuButton: {
    display: 'none',
    color: theme.palette.secondary.dark,
    height: 50,
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'inline-flex'
    }
  },
  menuHam: {
  }
}));

const navButton = [
  {
    label: 'ABOUT US',
    index: 0
  },
  /*{
    label: 'MISSION'
  },
  {
    label: 'VALUES'
  },
  {
    label: 'TEAM'
  },*/
  {
    label: 'CONTACT',
    index: 4
  }

]

function ButtonRender(props) {
  const classes = useStyles();
  //debugger
  return navButton.map((node, localIndex) => {
    //debugger
    return (
      <div key={localIndex} onClick={() => props.handleCallback(node.index)}>
        <Button className={classes.buttonStyle}>
          <div><Typography className={props.index === localIndex ? classes.buttonLabelBold : classes.buttonLabel}>{node.label}</Typography></div>
          <Grow timeout={1000} in={props.index === node.index}><div className={classes.buttonUnderline} /></Grow>
        </Button>
      </div>
    )
  })
}

export default function ButtonAppBar(props) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (index) => {
    console.log('TEST->' + index);
    
    setTimeout(() => {
      setAnchorEl(null);
      props.handleCallback(index);
    }, 0)
    //
  };

  //debugger
  //console.log('INDEX --> ' + props.index)

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div>
          <Typography className={classes.title}>
            > LITTHUB
          </Typography>
        </div>
        <div className={classes.buttonZone}>
          <ButtonRender {...props} />
          {/*<div>
            <Button className={classes.buttonStyle}>
              <div><Typography className={classes.buttonLabel}>ABOUT US</Typography></div>
              { props.index === 1 ? (<div className={classes.buttonUnderline}></div>) : (<div></div>) }
            </Button>
          </div>
          <div>
            <Button className={classes.buttonStyle}>
              <div><Typography className={classes.buttonLabel}>MISSION</Typography></div>
              <div className={classes.buttonUnderline}></div>
            </Button>
          </div>
          <div>
            <Button className={classes.buttonStyle}>
              <div><Typography className={classes.buttonLabel}>VALUES</Typography></div>
              <div className={classes.buttonUnderline}></div>
            </Button>
          </div>
          <div>
            <Button className={classes.buttonStyle}>
              <div><Typography className={classes.buttonLabel}>TEAM</Typography></div>
              <div className={classes.buttonUnderline}></div>
            </Button>
          </div>
          <div>
            <Button className={classes.buttonStyle}>
              <div><Typography className={classes.buttonLabel}>CONTACT</Typography></div>
              <div className={classes.buttonUnderline}></div>
            </Button>
          </div>*/}
        </div>
        <IconButton edge="end" className={classes.menuButton} aria-label="menu" onClick={handleMenu}>
          <MenuIcon />
        </IconButton>
        <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
                className={classes.menuHam}
              >
                {
                navButton.map((node, localIndex) => {
    
                return (
                  
                  <MenuItem onClick={() => handleClose(node.index)}>{node.label}</MenuItem>
                  //<MenuItem onClick={handleClose}>Profile</MenuItem>
                );})
                }
              </Menu>
      </div>

    </div>
  );
}